/**
 * border around store item with constant screen width
 */
AFRAME.registerComponent("item-border", {
    schema: {
        borderHalfSize: {type: "vec2", default: {x: 0.6, y: 0.6}},

        lineBaseWidth: {default: 0.02},
        edgeLineBaseWidth: {default: 0.024},
        lineWidth: {type: "number"},

        cornerRadius: {default: 0.05},
        /** distance between curve start and bezier control points */
        cornerControlPointDistance: {default: 0.55228},
        cornerSize: {default: 0.4},
        cornerNumPoints: {default: 8},

        /** update line width only when difference > `updateDeltaWidth` */
        updateDeltaWidth: {default: 0.001},

        color: {type: "color", default: "#FFF"},
        edgeColor: {type: "color", default: "#CCC"},
        activeColor: {type: "color", default: "#3F9CFF"},

        activeInDuration: {default: "200"},
        activeOutDuration: {default: "200"},

        opacity: {type: "number", default: 1},

        gazeFullLimit: {default: 0.1},
        gazeSemiLimit: {default: 0.3},
    },

    init: function () {
        this.bindMethods();

        this.cameraWorldPosition = new THREE.Vector3();
        this.worldPosition = new THREE.Vector3();
        this.screenPos = new THREE.Vector3();

        this.fullRange = {a: 0, b: this.data.gazeFullLimit};
        this.semiRange = {a: this.data.gazeFullLimit, b: this.data.gazeSemiLimit};
        this.zeroRange = {a: this.data.gazeSemiLimit, b: 1};

        this.el.setAttribute("item-border", "lineWidth", this.data.lineBaseWidth);
        this.edgeLineWidthRatio = this.data.edgeLineBaseWidth / this.data.lineBaseWidth;

        this.createLinesEl();

        this.prevGazeOffset = 1;
    },

    update: function (oldData) {
        const modifiedData = AFRAME.utils.diff(oldData, this.data);

        for (const modifiedKey in modifiedData) {
            switch (modifiedKey) {
                case "color":
                    this.lineEl.setAttribute("item-border-line", "color", this.data.color);
                    break;
                case "edgeColor":
                    this.edgeLineEl.setAttribute("item-border-line", "color", this.data.edgeColor);
                    break;
                case "lineWidth":
                    this.lineEl.setAttribute("item-border-line", "lineWidth", this.data.lineWidth);
                    this.edgeLineEl.setAttribute(
                        "item-border-line",
                        "lineWidth",
                        this.data.lineWidth * this.edgeLineWidthRatio
                    );
                    break;
                case "opacity":
                    this.lineEl.setAttribute("item-border-line", "opacity", this.data.opacity);
                    this.edgeLineEl.setAttribute("item-border-line", "opacity", this.data.opacity);
                    this.el.object3D.visible = this.data.opacity !== 0;
                    break;
            }
        }
    },

    tick: function () {
        this.doUpdateWorldVectors();
        this.lookAtCamera();
        this.doUpdateGaze();
        //this.doUpdateLineWidth();
    },

    doUpdateGaze: function () {
        this.screenPos.copy(this.worldPosition);
        this.screenPos.project(this.el.sceneEl.camera);
        const gazeOffset = Math.abs(Math.sqrt(Math.pow(this.screenPos.x, 2) + Math.pow(this.screenPos.y, 2)));

        if (isInRange(gazeOffset, this.fullRange) && !isInRange(this.prevGazeOffset, this.fullRange)) {
            this.el.emit("opacityFull");
        }
        else if (isInRange(gazeOffset, this.semiRange) && !isInRange(this.prevGazeOffset, this.semiRange)) {
            this.el.emit("opacitySemi");
        }
        else if (isInRange(gazeOffset, this.zeroRange) && !isInRange(this.prevGazeOffset, this.zeroRange)) {
            this.el.emit("opacityZero");
        }

        this.prevGazeOffset = gazeOffset;
    },

    /** update line width if difference is significant */
    doUpdateLineWidth: function () {
        const newWidth = this.getLineScaledWidth();

        if (Math.abs(this.data.lineWidth - newWidth) >= this.data.updateDeltaWidth) {
            this.el.setAttribute("item-border", "lineWidth", newWidth);
        }
    },

    /**
     * rotate border towards camera
     */
    lookAtCamera: function () {
        this.el.object3D.lookAt(this.cameraWorldPosition);
    },

    /**
     * update vectors that held world coordinates
     */
    doUpdateWorldVectors: function () {
        this.el.object3D.getWorldPosition(this.worldPosition);

        /* https://gitlab.com/ex-machina-reality/vr-commerce-platform/-/issues/2
         *
         * Due to an A-Frame or THREE.js bug getWorldPosition does not work correctly
         * for the camera and its descendants in VR. (it returns the camera-rig coordinates).
           https://github.com/mrdoob/three.js/issues/18448
        */
        this.cameraWorldPosition.setFromMatrixPosition(this.el.sceneEl.camera.matrixWorld);
    },

    /**
     * @returns distance between this element and camera
     */
    getDistanceToCamera: function () {
        return this.worldPosition.distanceTo(this.cameraWorldPosition);
    },

    /**
     * @returns line width scaled acording to distance to camera and camera FOV
     */
    getLineScaledWidth: function () {
        const cameraFov = this.getCamera().fov;
        const fovScale = Math.tan(((cameraFov / 2) * Math.PI) / 180);
        const distanceToCamera = this.getDistanceToCamera();
        // https://gitlab.com/ex-machina-reality/vr-commerce-platform/-/issues/2
        const attenuatedDistance = 5.805 * Math.log(9.75853 * distanceToCamera + 37.5252) - 21.263;

        return this.data.lineBaseWidth * attenuatedDistance * fovScale;
    },

    createLinesEl: function () {
        const lineEl = document.createElement("a-entity");

        lineEl.setAttribute("item-border-line", {
            borderHalfSize: this.data.borderHalfSize,

            cornerRadius: this.data.cornerRadius,
            cornerControlPointDistance: this.data.cornerControlPointDistance,
            cornerSize: this.data.cornerSize,
            cornerNumPoints: this.data.cornerNumPoints,

            color: this.data.color,
        });

        lineEl.setAttribute("overlay", "item-border");

        const edgeLineEl = document.createElement("a-entity");
        edgeLineEl.setAttribute("item-border-line", {
            borderHalfSize: this.data.borderHalfSize,

            cornerRadius: this.data.cornerRadius,
            cornerControlPointDistance: this.data.cornerControlPointDistance,
            cornerSize: this.data.cornerSize,
            cornerNumPoints: this.data.cornerNumPoints,

            color: this.data.edgeColor,
        });

        lineEl.setAttribute("overlay", "item-border-shadow");

        this.lineEl = this.el.appendChild(lineEl);
        this.edgeLineEl = this.el.appendChild(edgeLineEl);
    },

    /**
     * @returns  active THREE.js camera
     */
    getCamera: function () {
        return this.el.sceneEl.camera;
    },

    /**
     * bind methods used in callbacks
     */
    bindMethods: function () {},
});
